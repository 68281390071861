<!--表单页面拷贝开始-->
<template>
    <div>
      <form-components @submitForm="submitForm">
        <template v-slot:content>
          <el-form-item label="Xxxx" >
              <el-col :span="8">
                <el-input v-model="xxxx" ></el-input>
              </el-col>
            </el-form-item>
        </template>
      </form-components>
    </div>
  </template>
  
  <script>
  import FormComponents from "@/components/form/FormComponents";
  import TripEventLogApi from '@/api/TripEventLogApi'
  import { navTo,queryParam } from "@/utils/NavigatorUtils";
  export default {
    name: "SaveTripEventLogPage",
    props: {
      msg: String,
    },
    components: {
      FormComponents
    },
    data() {
      return {
        tripEventLog:{
          xxxx:"",
        }
      }
    },
    created(){
      this.tripEventLog = this.$route.params;
    },
    methods:{
      submitForm(){
        this.tripEventLog.id>0?this.updateTripEventLogById():this.addTripEventLog()
      },
      addTripEventLog(){
        TripEventLogApi.saveTripEventLog(this.tripEventLog).then((resp)=>{
          if(resp.code === '200'){
            this.tripEventLog={}
            this.$successMsg(resp.msg)
           
          }else{
            this.$errorMsg(resp.msg)
          }
        })
      },
      updateTripEventLogById(){
        TripEventLogApi.updateTripEventLog(this.tripEventLog).then((resp)=>{
          if(resp.code === '200'){
            this.user={}
            this.$successMsg(resp.msg);
             navTo(this,"/tripEventLogList")
          }else{
            this.$errorMsg(resp.msg)
          }
        })
      }
    }
  };
  </script>
  
  
  <!--表单页面拷贝结束-->